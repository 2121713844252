import { Button, ButtonVariant } from "brass-ui-kit";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import * as styles from "./CallToAction.module.scss";

const CallToAction: React.FC = () => {
  const handleClick = (e: any) => {
    e.preventDefault();
    const target = e.target.getAttribute("href");
    const location = document.querySelector(target).offsetTop;

    window.scrollTo({
      left: 0,
      top: location - 80,
    });
  };
  return (
    <section className={styles.CallToAction}>
      <div className={styles.CallToAction_container}>
        <StaticImage
          src="../../../assets/media/images/ebook/handbook-guide-lg.png"
          alt="ebook handbook"
          as="span"
        />
        <div className={styles.CallToAction_container_content}>
          <div className={styles.CallToAction_container_content_details}>
            <h3 className={styles.CallToAction_container_content_details_title}>
              Brought to you by Brass, the financial partner for your business.
            </h3>
            <p className={styles.CallToAction_container_content_details_desc}>
              Get the free e-book and access to weekly guides as they are
              released
            </p>
          </div>
          <Button
            variant={ButtonVariant.Primary}
            onClick={handleClick}
            className={styles.CallToAction_container_content_btn}
            as={"a"}
            href="#getEbook"
          >
            Get it for free
          </Button>
        </div>
      </div>
    </section>
  );
};

export default CallToAction;
